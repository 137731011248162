<template>
<div>
    <b-row>
        <b-col lg="8" xl="8" offset-lg="2" offset-xl="2">
            <b-card no-body>
                <b-card-header>
                <b-card-title>135 UGG, Mes 4 de 18, Peso Promedio (Kg)</b-card-title>
                <feather-icon
                    icon="SettingsIcon"
                    size="18"
                    class="text-muted cursor-pointer"
                />
                </b-card-header>

                <b-card-body class="pb-0">
                <!-- apex chart -->
                <vue-apex-charts
                    type="line"
                    height="240"
                    :options="revenueComparisonLine.chartOptions"
                    :series="revenueComparisonLine.series"
                />
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>

    <b-row>
        <b-col lg="8" xl="8" offset-lg="2" offset-xl="2">
            <b-card no-body>
                <b-card-header>
                <div class="d-flex align-items-center">
                    <feather-icon
                    size="24"
                    class="mr-1"
                    icon="ListIcon"
                    />
                    <b-card-title class="ml-25">
                    Linea de tiempo
                    </b-card-title>
                </div>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                />
                </b-card-header>

                <!-- timeline -->
                <b-card-body>
                <app-timeline>
                    <app-timeline-item variant="primary">
                        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                            <h6>Vacunación</h6>
                            <small class="timeline-item-time text-nowrap text-muted ml-1">15 Enero 2021</small>
                        </div>
                        <p>Se vacunaron 135 UGG, adjunto factura</p>
                        <b-media no-body>
                            <b-media-aside class="mr-1">
                            <b-img
                                :src="require('@/assets/images/icons/doc.png')"
                                height="23"
                                alt="factura.png"
                            />
                            </b-media-aside>
                            <b-media-body class="my-auto">
                            <h6 class="media-body mb-0">
                                factura.png
                            </h6>
                            </b-media-body>
                        </b-media>
                    </app-timeline-item>

                    <app-timeline-item variant="primary">
                        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                            <h6>Muerte</h6>
                            <small class="timeline-item-time text-nowrap text-muted ml-1">10 Enero 2021</small>
                        </div>
                        <p>Murieron 2 UGG en un Accidente, adjunto fotos</p>
                        <b-media no-body>
                            <b-media-aside class="mr-1">
                            <b-img
                                :src="require('@/assets/images/icons/doc.png')"
                                height="23"
                                alt="factura.png"
                            />
                            </b-media-aside>
                            <b-media-body class="my-auto">
                            <h6 class="media-body mb-0">
                                uggs-falecidas.png
                            </h6>
                            </b-media-body>
                        </b-media>
                    </app-timeline-item>

                    

                    <app-timeline-item
                    title="Se compraron 137 UGG"
                    subtitle="Peso promedio 255kg"
                    time="12 Noviembre 2020"
                    variant="success"
                    />
                </app-timeline>
                <!--/ timeline -->
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-col md="3" lg="3" offset-lg="9" offset-xl="9">
            <b-button variant="primary" block>Contactar Productor</b-button>
        </b-col>
      </b-col>
    </b-row>
</div>
  
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCardText, BRow, BCol, BImg, BMedia, BMediaBody, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BButton,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    VueApexCharts,
    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BImg,
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      avatars: [
        { userImg: require('@/assets/images/portrait/small/avatar-s-9.jpg'), name: 'Billy Hopkins' },
        { userImg: require('@/assets/images/portrait/small/avatar-s-6.jpg'), name: 'Amy Carson' },
        { userImg: require('@/assets/images/portrait/small/avatar-s-8.jpg'), name: 'Brandon Miles' },
        { userImg: require('@/assets/images/portrait/small/avatar-s-7.jpg'), name: 'Daisy Weber' },
        { userImg: require('@/assets/images/portrait/small/avatar-s-20.jpg'), name: 'Jenny Looper' },
      ],
      revenue: {},
      revenueComparisonLine: {
        series: [
          {
            name: 'Real',
            data: [250, 270, 285, 298],
          },
          {
            name: 'Estimado',
            data: [245, 265, 285, 305],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            offsetX: -10,
          },
          stroke: {
            dashArray: [0, 12],
            width: [4, 3],
          },
          legend: {
            show: false,
          },
          colors: ['#00BD56', '#59595B'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: [$themeColors.primary, '#59595B'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: ['Mes 1', 'Mes 2', 'Mes 3', 'Mes 4'],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(0)}k` : val
              },
            },
          },
          grid: {
            borderColor: '#e7eef7',
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
    }
  },
  created() {
    this.$http.get('/card/card-analytics/revenue').then(res => { this.revenue = res.data })
  },
}
</script>
